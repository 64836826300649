@import 'variables';

.procedure-collapsible-selection {
  // TODO(lucky): should move font family to body
  font-family: Nunito, sans-serif;
  width: 100%;

  .content {
    margin-top: 2rem;

    &-empty {
      font-size: 15px;
      color: map-get($Colors, 'neutral_500');
      text-align: center;
      max-width: 320px;
      margin: 0 auto 2rem;
    }
  }

  .field {
    &-placeholder {
      position: relative;
    }

    &-sub-label {
      position: absolute;
      color: map-get($Colors, 'neutral_500');
      font-size: 16px;
      width: max-content;
      top: calc(100% + -1em);
    }

    &-label {
      color: map-get($Colors, 'neutral_600');
      font-size: 18px;
      padding-left: 5px;
      display: inline-flex;
      align-items: center;

      &--sub {
        color: map-get($Colors, 'neutral_500');
      }

      &--placeholder {
        color: map-get($Colors, 'neutral_300');
      }

      .tooltip-wrapper {
        margin-left: 5px;
        height: 24px;
      }
    }

    &-summary {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        display: flex;
        align-items: center;
      }
    }
  }

  details > summary {
    list-style: none;

    &::-webkit-details-marker {
      display: none;
    }
  }

  .accordion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid map-get($Colors, 'neutral_100');

    &-label {
      display: flex;
      align-items: center;
    }
  }
}

.badge-button {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.badge-button:not(.disabled):hover .badge {
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.3));
}

.badge-button:not(.disabled):active .badge {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
}


.inbox{
  box-shadow: 0px 2px 4px rgba(111, 131, 141, 0.325068);
  border-radius: 25px;
  padding-right: 70px;
  background: #FFFFFF;
  min-width: 800px;
  min-height: 400px;
  // max-height: 650px;
}

.inbox:hover{
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
}

.inbox_screen {

  .floating_container {
    padding: 0;
    border-radius: 25px;
    background-color: transparent;
    box-shadow: none;
  }

  .inbox {
    width: 100%;
  }
}
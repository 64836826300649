.current_offer_container {
  height: 85px;
  width: 320px;
  border-radius: 45px;
  background-color: #ffffff;
  position: absolute;
  top: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cancel-btn {
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #6f838d;
  position: absolute;
  top: 60%;
  font-weight: 700;
  color: #6f838d;
  text-transform: none;
}

.cancel-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: #6f838d;
}

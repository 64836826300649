div.StripeElement {
  margin-left: 0;
}

input,
.StripeElement {
  display: block;
  padding: 12px 0px;
  padding-left: 12px;
  font-size: 1em;
  font-family: sans-serif;
  font-smooth: antialiased;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  -webkit-font-smoothing: auto;
}

input::placeholder {
  color: #AAB4B9;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

.container {
  width: 100%;
  height: 64px;
  display: flex;
}

.nav_container {
  position: fixed;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 64px;
}

.logo {
  width: 160px;
  height: 85px;
}

.buttons_contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.self_contain {
  width: 140px;
  display: flex;
  align-items: center;
}

.self_contain p {
  font-family: Nunito;
  font-size: 18px;
  color: white;
}
.link {
  color: #435963;
  font-family: Nunito;
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0px;
  text-decoration: none;
}

.blue-link {
  color: #38afcb;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

.app_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
}

.app_btn p {
  color: #435963;
  font-family: Nunito;
  font-size: 12px;
}

.side_bar_contain {
  flex: 30%;
  min-width: 350px;
  max-width: 450px;
  min-height: 600px;
  padding-bottom: 32px;
  background-color: white;
}

.navigation_contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 64px;
}

.link:hover {
  color: rgba($color: #435963, $alpha: 0.4);
}

.blue-link:hover {
  color: rgba($color: #38afcb, $alpha: 0.4);
}

.active-link {
  color: #99CE00;
}
.active-blue-link {
  color: #3DAFC9;
}

.title {
  height: 32px;
  width: 201px;
  color: #ffffff;
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.textarea {
  width: 100%;
  border-color: #3DAFC9;
  border-width: 1px;
  background-color: white;
  border-radius: 20px;
  max-height: 100px;
  font-family: Nunito;
  font-size: 14px;
}

.suggestion_container {
  height: 50px;
  width: 100%;
  border: 1px solid #6f838d;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
}

.suggestion_inner_container_active {
  background-color: #3DAFC9;
  width: 120px;
  border-radius: 50px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.suggestion_inner_container_inactive {
  width: 120px;
  border-radius: 50px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inner_text {
  color: #435963;
  font-family: Nunito;
  font-size: 16px;
  margin: 0;
}

.inner_text_active {
  color: #fff;
  font-family: Nunito;
  font-size: 16px;
  margin: 0;
}

.inner_text:hover {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.spacing:hover {
  font-weight: 600;
  letter-spacing: 5px;
  -webkit-transition: letter-spacing 0.6s ease;
  transition: letter-spacing 0.6s ease;
}

.spacing {
  font-weight: 600;
  letter-spacing: 0px;
  -webkit-transition: letter-spacing 0.6s ease;
  transition: letter-spacing 0.6s ease;
}

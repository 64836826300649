.popover-container {
  position: relative;
}

.popover-target {
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

.popover-box {
  position: absolute;
  left: 50%;
  background-color: #fff;
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.15)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
  padding: 20px;
  z-index: 4;
  border-radius: 12px;
  min-width: 200px;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // Chevron for bottom position
  &.bottom::before {
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }

  // Chevron for top position
  &.top::before {
    bottom: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
}

.popover-content {
  width: 100%;
}

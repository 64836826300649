@import 'variables';

.button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 25px;
    border: 0;
    outline: 0;
    display: inline-block;
    padding: 0 14px;
    margin-top: 10px;
    cursor: pointer;

    .icon {
        margin-right: 8px;
        padding: 0px 5px 0px 0px;
    }

    .text {
        font-family: 'Nunito', sans-serif;
        color: map-get($Colors, 'white');
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.025em;
        text-decoration: none;
        white-space: nowrap;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
    }

    &.disabled {
        pointer-events: none;
    }

    &:hover {
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
    }

    &:active {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    }

    &.xsmall {
        height: 24px;
        width: 75px;

        .icon {
            width: 12px;
            height: 12px;
        }

        .text {
            font-size: 12px;
        }
    }

    &.small {
        height: 48px;
        width: 200px;

        .icon {
            width: 24px;
            height: 24px;
        }

        .text {
            font-size: 18px;
        }
    }

    &.medium {
        height: 48px;
        width: 296px;

        .icon {
            width: 24px;
            height: 24px;
        }

        .text {
            font-size: 18px;
        }
    }

    &.large {
        height: 48px;
        width: 100%;

        .icon {
            width: 24px;
            height: 24px;
        }

        .text {
            font-size: 18px;
        }
    }

    &.primary {
        background-color: map-get($Colors, 'primary_500');

        &.disabled {
            background-color: map-get($Colors, 'primary_200');
        }

        &:active {
            background-color: map-get($Colors, 'primary_400');
        }
    }

    &.secondary {
        background-color: map-get($Colors, 'neutral_500');

        &.disabled {
            background-color: map-get($Colors, 'neutral_200');
        }

        &:active {
            background-color: map-get($Colors, 'neutral_300');
        }
    }

    &.tertiary {
        background-color: map-get($Colors, 'success_500');

        &.disabled {
            background-color: map-get($Colors, 'success_200');
        }

        &:active {
            background-color: map-get($Colors, 'success_400');
        }
    }
}
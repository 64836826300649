.carousel {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel-controls {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.carousel-slider {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

.carousel-slide {
  width: 100%;
  height: 100%;
  position: absolute;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.star-size {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.star-size:hover {
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.3));
}

.star-size:active {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
}
@import 'variables';

:root {
  --checkbox-size: 18px;
}

.selectable-chip {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;

  &-item {
    padding: 14px 20px;
    display: inline-flex;
    align-items: center;
    border: 1.5px solid map-get($Colors, 'neutral_100');
    border-radius: 8px;
    cursor: pointer;
    color: map-get($Colors, 'neutral_600');

    &:has(input[type="checkbox"][checked]){
      border-color: map-get($Colors, 'secondary_500');

      input[type="checkbox"] {
        &::before {
          background-image: url('../images/checkbox_filled.svg');
        }
      }
    }

    label {
      cursor: inherit;
    }

    input[type="checkbox"] {
      appearance: none;
      border: none;
      box-shadow: none;
      width: var(--checkbox-size);
      height: var(--checkbox-size);
      margin: 0 13px 0 0;
      padding: 0;
      position: relative;
      cursor: inherit;

      &::before {
        content: '';
        background-image: url('../images/checkbox_empty.svg');
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: var(--checkbox-size);
      }
    }
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-target {
  cursor: pointer;
}

.tooltip-box {
  position: absolute;
  bottom: -36px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #8E9BA1;
  color: #fff;
  padding: 4px 8px;
  height: 24px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0.9;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito;
}

.tooltip-box::after {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #8E9BA1 transparent;
}

.title {
  height: 32px;
  width: 201px;
  color: #ffffff;
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.default_circle_container {
  min-width: 100px;
  min-height: 100px;
  border-radius: 100px;
  border: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.std_font {
  font-weight: 400;
  font-size: 16px;
  color: white;
  margin: 0;
}

.bold_font {
  font-weight: 700;
  font-size: 18px;
  color: #435963;
  margin: 0;
}
.row_style {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid #ededed;
  position: relative;
}

.row_style_bold {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #6f838d;
}

.line_2 {
  width: 350px;
  border-bottom: 1px solid #ededed;
  margin-top: 0;
  margin-bottom: 5px;
}
.line_400 {
  width: 400px;
  border-bottom: 1px solid #ededed;
  margin-top: 0;
  margin-bottom: 5px;
}
.line_dark {
  width: 400px;
  border-bottom: 2px solid #6f838d;
  margin-top: 0;
  margin-bottom: 5px;
}

.text_notes {
  width: 96%;
  height: 150px;
  border: 2px solid #3dafc9;
  border-radius: 25px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) inset;
  outline: none;
  padding: 10px;
  font-family: Nunito;
  font-size: 16px;
  resize: none;
  font-weight: normal;

  &::placeholder {
    color: #AAB4B9;
  }
}

@media all and (max-width: 980px) {
  .back {
    position: absolute;
    top: 160px;
    color: white;
    font-size: 22px;
    left: 400px;
    font-weight: 600;
    cursor: pointer;
  }
}

@media all and (min-width: 980px) {
  .back {
    position: absolute;
    top: 70px;
    color: white;
    font-size: 22px;
    left: 30%;
    font-weight: 600;
    cursor: pointer;
  }
}

.alert-enter {
  opacity: 0;
}
.alert-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.card-text-container {
  width: 165px;
  border-bottom: #b0bec5 1px solid;
  margin: 10px 0;
}

.edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.15),
    0 2px 3px 0 rgba(0, 0, 0, 0.15);
  background: #4FC0CF;
  transition: all 0.3s ease-in-out;
}

.trash-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.15),
    0 2px 3px 0 rgba(0, 0, 0, 0.15);
  background: #8E9BA1;
  transition: all 0.3s ease-in-out;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  p {
    font-family: Nunito;
    font-size: 16px;
    color: #435963;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  &:hover {
    cursor: pointer;
    font-weight: bold;

    .edit-button {
      background: #7BDEE5;
    }

    .trash-button {
      background-color: #A9B3B7;
    }
  }
}

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

body {
  font-family: 'Nunito', sans-serif;
}

.component_container {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  flex: 1;
  min-width: 1200px;
}

.floating_container {
  background-color: white;
  width: 100%;
  min-width: 800px;
  margin: 25px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 10px;
}

.floating_container_all_screen {
  background-color: white;
  width: 100%;
  min-width: 800px;
  margin: 25px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  padding: 10px;
  min-height: 100%;
}
.chat_component {
  overflow-y: auto;
  max-height: 60vh;
}
.card-shadow {
  background-color: white;
  width: 60%;
  max-width: 500px;
  border-radius: 15px;
  border: 1px solid #ededed;
  padding: 22px;
  margin: 15px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.card-shadow:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.shadow {
  box-shadow: 0 2px 4px rgba(111, 131, 141, 0.325068);

  &:hover {
    box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.4);
    cursor: pointer;
  }
}

.dashboard-top-button {
  align-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  padding: 0;

  @extend .shadow;
}

.dashboard-billing-shadow {
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  height: 230px;
  justify-content: space-evenly;
  padding: 0 24px;

  @extend .shadow;
}

.dashboard-todays-shift {
  background-color: #ffffff;
  border-radius: 25px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  padding-top: 31px;
  padding-right: 20px;

  @extend .shadow;
}

.dashboard-buttons-shadow {
  display: flex;
  flex: 1;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
  flex: 1;
  box-shadow: 0px 3px 10px -3px rgba(115, 135, 145, 0.4);
  border-radius: 32.5px;
}

.dashboard-buttons-shadow:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.permanent-cards-shadow {
  border-radius: 25px;
  min-height: 140px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0px 3px 10px -3px rgba(115, 135, 145, 0.4);
}

.permanent-cards-without-shadow {
  border-radius: 25px;
  min-height: 140px;
  padding: 15px;
  margin-bottom: 10px;
}

.permanent-cards-shadow:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.dashboard-shifts-shadow {
  align-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0 3px 10px -3px rgba(115, 135, 145, 0.4);
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 230px;
  justify-content: space-evenly;
}

.dashboard-shifts-shadow:hover {
  box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.card-request {
  background-color: white;
  width: 60%;
  border-radius: 50px;
  border: 1px solid #ededed;
  padding: 10px;
  margin: 15px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  max-width: 650px;
}

.card-request:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.counter-offer-view {
  width: 120px;
  background-color: #f4511e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter-offer-view:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.counter-offer-clickable-area {
  width: 280px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.counter-offer-clickable-area:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}
.global_font {
  font-family: Nunito;
}

.f-red {
  color: #D00019;
}

.f-dark {
  color: #435963;
}

.f-light {
  color: #6f838d;
}

.f-12 {
  font-size: 12px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.button-capitalize {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-capitalize {
  text-transform: capitalize !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  box-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.4);
  cursor: pointer;
}

.button:active {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.bold {
  font-weight: 700;
}

.time_contain {
  width: 100px;
  background-color: #b0bec5;
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  margin: 15px;
  color: white;
  cursor: pointer;
}

.time_contain:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.time_contain:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
}

.time_of_day_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  position: relative;
}

.time_of_day_container:before {
  content: '';
  position: absolute;
  width: 46px;
  height: calc(30px * 2);
  border-radius: 15px;
  z-index: 2;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 3.3px 0px rgba(0, 0, 0, 0.35) inset;
  pointer-events: none;
}

.top_time_btn {
  background: #CBD4D8;
  color: white;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: normal;
  width: 46px;
  height: 30px;
  display: flex;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.bottom_time_btn {
  background: #CBD4D8;
  color: white;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: normal;
  width: 46px;
  height: 30px;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.top_time_btn:hover {
  background-color: #307F92;
  color: #fff;
}

.bottom_time_btn:hover {
  background-color: #307F92;
  color: #fff;
}

.selected_time_of_day {
  background-color: #307F92;
  color: #fff;
}

.selected_time {
  background-color: #3dafc9;
}

.button_group_container {
  display: 'flex';
  align-items: 'center';
  position: relative;
}

.button_group_container:before {
  content: '';
  position: absolute;
  height: 45px;
  border-radius: 15px;
  z-index: 2;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 3.3px 0px rgba(0, 0, 0, 0.35) inset;
  pointer-events: none;
}

.button_group_container_first_option {
  background: #CBD4D8;
  width: 50px;
  height: 45px;
  display: flex;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button_group_container_option {
  background: #CBD4D8;
  width: 50px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button_group_container_last_option {
  background: #CBD4D8;
  width: 50px;
  height: 45px;
  display: flex;
  align-items: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.selected_button_group_option {
  background-color: #307F92;
}

@keyframes waitingOnPoint {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(40px);
  }
  50% {
    transform: translateY(20px);
  }
  75% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

//referral
.title-text-32 {
  color: #307F92;
  font-family: Nunito;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
}

.title-text-24 {
  color: #99ce00;
  font-family: Nunito;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.title-text-16 {
  color: #435963;
  font-family: Nunito;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.description-text-14 {
  color: #435963;
  font-family: Nunito;
  font-size: 14px;
  text-align: center;
}

.input-style {
  width: 440px;
  border-radius: 10px;
  background-color: #fbfbfb;
  border: 1px solid #b0bec5;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  font-family: Nunito;
  font-size: 16px;
  margin-left: 0px;
}

.input-field {
  color: #435963;
  font-family: Nunito;
  width: 170px;
  height: 42px;
  margin-left: 0;
  font-size: 16px;
  display: flex;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: #F4F8FA;
  border: none;
  padding: 0 20px;
  border-radius: 5px;

  &::placeholder {
    color: #AAB4B9;
    font-size: 16px;
  }
}

.select-field {
  color: #435963;
  font-family: Nunito;
  font-weight: bold;
  width: 170px;
  height: 42px;
  font-size: 18px;
  display: flex;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: #F4F8FA;
  border: none;
  padding: 0 20px;
  border-radius: 5px;
  appearance: none;
  background: url('../images/thinArrowDown.svg') no-repeat 96% center #F4F8FA;
}

.grey-button {
  background-color: #819096;
  border-radius: 25px;
  height: 50px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blue-button {
  background-color: #307F92;
  border-radius: 25px;
  height: 50px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.light-blue-button {
  background-color: #C5E4EB;
  border-radius: 25px;
  height: 50px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.green-button {
  background-color: #99ce00;
  border-radius: 25px;
  height: 50px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.red-button {
  background-color: #f4511e;
  border-radius: 25px;
  height: 50px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.cancel-button {
  width: 152px;
  height: 48px;
  background-color: #697a82;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-button {
  width: 152px;
  height: 48px;
  background-color: #307F92;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-blue-button {
  background-color: white;
  border: 2px solid #307F92;
  border-radius: 25px;
  height: 50px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blue-text-18 {
  color: #307F92;
  font-family: Nunito;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.white-text-18 {
  color: white;
  font-family: Nunito;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.gray-text-18 {
  color: #435963;
  font-family: Nunito;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.link-text {
  color: #435963;
  font-family: Nunito;
  text-align: center;
  margin: 10px;
  text-decoration: underline;
  text-align: center;
}

.textarea::placeholder {
  color: #AAB4B9;
  font-size: 16px;
  font-family: Nunito;
}

.favorite-card-container {
  padding: 35px 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.textarea-style {
  width: 100%;
  height: 100px;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 16px 20px;
  font-size: 16px;
  font-family: Nunito;
  background-color: #F4F8FA;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;

  &::placeholder {
    color: #AAB4B9;
  }
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  transform: translate(-77%, 4%);
  border-radius: 25px;
  padding: 15px 18px 18px 32px;
  background-color: #a9b3b7;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito;
  z-index: 4;
  display: flex;
  align-items: center;
  text-align: left;
  width: 350px;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -18px;
  left: 80%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #a9b3b7 transparent;
}

button {
  font-family: inherit;
  border-color: transparent;
  cursor: pointer;
}

.hidden {
  display: none;
}

.button-reset {
  appearance: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-family: inherit;
}

.warning-banner-container:hover {
  position: relative;
  z-index: 99;
}

.autocomplete {
    position: relative;

    .options {
        display: flex;
        flex-direction: column;
        position:absolute;
        overflow: auto;
        max-height: 240px;
        top: 48px;
        min-width: 100%;
        white-space: nowrap;
        background-color: var(--neutral_150);
        border-radius: 10px;
        box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
        padding: 10px 0px;

        .options-item {
            align-items: center;
            background-color: inherit;
            border-radius: 8px;
            color: var(--neutral_600);
            cursor: pointer;
            display: flex;
            margin: 0px 10px;
            padding: 8px 10px;

            &:hover {
                background-color: var(--secondary_500);
                color: var(--white);
            }
        }
    }
}
.shift-item {
  background-color: #f4f8fa;
  border-radius: 50px;
  transition: 300ms ease;
  width: 100%;

  &:hover {
    box-shadow:
      0px 2px 6px 2px #00000026,
      0px 1px 2px 0px #00000026;
  }

  .pressable {
    &:hover {
      opacity: 1;
    }
  }
}

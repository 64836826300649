@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

.footer-box{
  width: 100%;
  height: 116px;
}

.temp-mee-inc {
  width: 260px;
  color: #ffffff;
  font-family: Nunito;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
}

.icon_box {
  width: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.icon_box img:hover {
  outline: #7ED957;
}

.background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #314753;
  flex-direction: column;
}


.line {
  border-bottom: 1px solid #ffffff;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.link_content_container {
  min-width: 600px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.link_container {
  margin-top: 30px;
  margin-left: 50px;
  gap: 35px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;

}

.link_container a {
  font-family: Nunito;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  margin: 10px;
  text-transform: uppercase;
}

.link_container a:hover {
  font-family: Nunito;
  font-size: 14px;
  color: #7ED957;
  text-decoration: none;
  margin: 10px;
  text-transform: uppercase;
}



.overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 8;
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
}

#diputeReasonModal {
    position: absolute;
    z-index: 9;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
      
    .modal_content {
        position: relative;
        width: auto;
        top: -100px;

        h1 {
            font-family: Nunito;
            font-size: 28px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 0em;
            text-align: center;
            margin-bottom: 20px;
            color: #435963;
            margin-top: 0;
            margin-bottom: 20px;
        }

        .subheading {
            font-size: 14px; 
            line-height: 19px; 
            color: #435963;
            font-family: Nunito; 
            text-align: center;
            margin-bottom: 15px;
            width: 243px;
        }

        .close_button {
            margin-top: 20px;
            margin-right: 20px;
            color: #B0BEC5;
            cursor: pointer;
            width: -webkit-fill-available;
            text-align: right;
        }

        textarea {
            font-size: 16px; 
            margin-left: 35px;
            margin-right: 35px;
            width: -webkit-fill-available;
            height: 192px;
            border-radius: 10px;
            box-shadow: 0px 2px 4px 0px #00000040 inset;
            border-color: white;
            background-color: #F4F8FA;
            color: #435963;
            
            &:focus {
                color: #A9B3B7;
            }

            &::placeholder {
                color: #AAB4B9;
                font-family: Nunito;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
            }
        }
        
        .button_container {
            display: flex; 
            justify-content: center; 
            margin: 22px 0px 29px;
            p {
                text-transform: none;
            }
        }

        .button_custom {
            &:disabled {
                background-color: #C5E4EB !important;
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

.tmLogo {
  height: 67px;
  width: 126px;
}

.header {
  height: 64px;
  background-color: #307F92;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.links {
  text-decoration: none;
  height: 27px;
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: bold;
  list-style: none;
}

.links:hover {
  color: rgba($color: #fff, $alpha: 0.4);
}

.active-link {
  color: #99ce00;
}

.active-blue-link {
  color: #3dafc9;
}

.nav_links {
  display: flex;
  width: 600px;
  justify-content: space-evenly;
}

.text_center {
  width: 278px;
  color: #ffffff;
  font-family: Nunito;
  font-size: 40px;
  font-weight: bold;
  line-height: 54px;
}

.content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.hygienist_text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#create_account {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.create_tm {
  height: 41px;
  width: 431px;
  color: #435963;
  font-family: 'Nunito', sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 41px;
  text-align: center;
}

.create_container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  border: none;
  border-bottom: 1px solid #b0bec5;
  width: 100%;
  outline: none;
  background-position: 5px 12px;
  background-repeat: no-repeat;
  height: 20px;
  font-size: 16px;
  padding-left: 25px;
  font-family: 'Nunito', sans-serif;
  box-shadow: none;
  border-radius: 0;
  margin-left: 0;
}

.show-hide-password {
  border-bottom: 1px solid #b0bec5;
  height: 20px;
  text-align: center;
  margin: 0px;
  color: #3dafc9;
  font-family: Nunito;
  cursor: pointer;
  margin-top: 20px;
  padding-bottom: 10px;
}
.input-forgot {
  border: none;
  border-bottom: 1px solid #b0bec5;
  width: 100%;
  outline: none;
  background-position: 5px 12px;
  background-repeat: no-repeat;
  height: 20px;
  font-size: 16px;
  padding-left: 25px;
  font-family: 'Nunito', sans-serif;
  box-shadow: none;
  border-radius: 0;
  margin-left: 0;
}

.input-forgot:focus {
  border-bottom: 1.1px solid #99ce00;
  transition: border-bottom 0.2s;
  box-shadow: none !important;
}

.input:focus {
  border-bottom: 1.1px solid #99ce00;
  transition: border-bottom 0.2s;
  box-shadow: none !important;
}

.input_names {
  height: 24px;
  width: 170px;
  color: #435963;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.or {
  height: 24px;
  width: 27px;
  color: #435963;
  font-family: Nunito;
  font-size: 18px;
  line-height: 24px;
}

.button:hover {
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.4);
}

.button:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
}

.title_font {
  min-height: 27px;
  font-family: Nunito;
  font-size: 20px;
  font-weight: bold;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  margin-bottom: 10px;
  // display: inline-block;
  color: #666;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #4FC0CF;
  border-radius: 100%;
  background: #fff;
}
[type='radio']:checked + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #4FC0CF;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.confirm_code {
  width: 30px;
  height: 30px;
  margin: 15px;
  text-align: center;
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: 600;
}

.characters {
  height: 50px;
}

.code-container {
  height: 42px;
  gap: 10px;
  width: 250px;

  input {
    opacity: 0;
  }
}

.code-character {
  height: 42px;
  line-height: 42px;
  font-size: 30px;
  background-color: #F4F8FA;
  border: none;
  border-radius: 12px;
  color: #435963;
  text-align: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.code-character--selected {
  color: #435963;
  outline: red 2px;
  background-color: #F4F8FA;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

}

.verification-input input {
  background-color: #000000;
}

.sm-links {
  text-align: center;
  margin: 0;
  color: #3dafc9;
  font-family: 'Nunito';
  font-size: 16px;
  cursor: pointer;
}

.sm-links:hover {
  color: #dedede;
}
@media screen and (min-width: 768px) {
  .header {
    min-width: 786px;
  }
}

.alert-box {
    width: 350px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.5);
    min-height: 200px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pressable {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  appearance: none;

  &:hover {
    opacity: 0.8;
  }
}

@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');

.calendar_container {
  width: 100%;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  min-height: 400px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-evenly;
}

.DayPicker_font_size {
  font-size: 10px !important;
}

.btn_container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 25px;
}

.jobs_container {
  width: 100%;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  min-height: 300px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 500px;
}

.job {
  display: flex;
  border-radius: 30px;
  margin: 10px;
  flex-direction: row;
  width: 90%;

  // justify-content: center;
  align-items: center;
}

.job:hover {
  box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.3);
}

.job:active {
  box-shadow: 0px 1px 2px rgba($color: #000000, $alpha: 0.5);
}

.job_list {
  margin-bottom: 25px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.font_bold {
  font-size: 16px;
  font-family: Nunito;
  font-weight: 700;
  color: #435963;

}

.font_og {
  font-size: 16px;
  font-family: Nunito;
  font-weight: 300;
  color: #435963;
}

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
  font-family: Nunito;
}

.DayPicker-wrapper {
  position: relative;
  outline: none;
  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 15px 10px;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  outline: none;
  margin-right: 23.5em;
  background-image: url('../images/Arrow_left.png');
}

.DayPicker-NavButton--next {
  background-image: url('../images/Arrow_right.png');
  outline: none;
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
  text-align: center;
  color: #434963;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #434963;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 1em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.rdp-caption_label {
  font-family: "Nunito";
  font-weight: 500;
  font-size: 1.15em;
  text-align: center;
  color: #434963;
}

.rdp-nav_icon {
  path {
    fill: #3DAFC9;
  }
}

.rdp-nav_button:hover {
  background-color: transparent !important;
}

.rdp-nav_button[disabled]:not(.rdp-day_selected) {
  opacity: 0 !important;
}

.rdp-caption_label {
  font-family: "Nunito";
  font-weight: 700;
  font-size: 22px;
  color: #435963;
  padding-left: 12px;
}

.rdp-head_cell {
  display: table-cell;
  padding: 0.5em;
  color: #8E9BA1;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
}

.rdp-cell {
  padding: 8px 8px
}

.rdp-button {
  font-family: "Nunito";
  box-shadow: none !important;
  cursor: pointer;
}

.rdp-button:hover {
  color: #dce0e0;
  transform: none;
  box-shadow: none;
}

.rdp-day {
  font-size: 18px;
  color: #435963;
  height: 34px;
  width: 34px;
}

.rdp-day_today {
  color: #FFFFFF;
  background-color: #307F92;
  font-weight: 700;
  outline: none;
}

.rdp-day_today:hover:not(.rdp-day_selected){
  background-color: #3DAFC9 !important;
}

.rdp-day_today.rdp-day_selected:hover {
  color: #FFFFFF !important;
  background-color: #3DAFC9;
}

.rdp-day_outside {
  color: #8b9898;
  cursor: default;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  color: #dce0e0;
  cursor: default;
  opacity: 1;
}

.rdp-day_sunday {
  background-color: #f7f8f8;
}

.rdp-day_sunday:not(.rdp-day_today) {
  color: #dce0e0;
}

.rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside):hover {
  background-color: #3DAFC9;
  color: #FFFFFF;
}

.rdp-day_disabled {
  background-color: transparent;
  color: #dce0e0;
}
.rdp-day_disabled:hover {
  background-color: transparent;
  color: #dce0e0;
}

.rdp-day_selected {
  color: #FFFFFF;
  background-color: #3DAFC9;
  font-weight: 700;
}

.rdp-day_selected:hover {
  color: #FFFFFF;
  background-color: #3DAFC9;
}

.rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected):not(.rdp-day_outside):hover {
  background-color: #3DAFC9;
  color: #FFFFFF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
